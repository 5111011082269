// Carrinho vazio
.carrinho-empty {
  padding: 40px 0;
}

//TABELA
.cart-tabela-container {
  border: 1px solid #DDD;
  padding: 20px;

  // MOBILE
  @include mq(s) {
    padding: 0px;
    border: none;
  }
}

// Number picker
.cart-tabela-container .number-picker {
  float: none;
  display: inline-block;
  width: auto;
  margin-bottom: 0px;
  background: rgba(#000, 0.1);
  padding: 0 10px;
  border-radius: 20px;

  .number-picker-input input {
    font-size: 17px;
  }
}

// Resumo
.cart-resumo {
  padding: 20px 40px;
  //background: $cor_fundo2;

  // MOBILE
  @include mq(s) {
    padding: 20px;
  }

  .segura-botao-carrinho {

    // MOBILE
    @include mq(s) {
      margin-bottom: 20px;
      text-align: center;
    }
  }
}

.cart-resumo-titulo {
  font-size: 24px;
  font-weight: bold;
  color: $cor_fonte_escura;
  text-align: center;
  border-bottom: 1px solid rgba(#000, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;

  // MOBILE
  @include mq(s) {
    font-size: 20px;
  }
}

.cart-resumo-itens {
  b {
    font-size: 18px;
    font-weight: bold;
    color: $cor_fonte_escura;

    // MOBILE
    @include mq(s) {
      font-size: 16px;
    }
  }

  .right {
    text-align: right;
  }
}

.cart-resumo .btn-container {
  margin-top: 10px;
  margin-bottom: 10px;

  .btn {
    width: 100%;
    margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

// Botão remover
.cart-rem {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  background: rgba(#000, 0.1);
  color: $error_color;

  &:hover {
    background: $error_color;
    color: #fff;
  }
}

.cart-btns {

  .btn {
    margin: 5px 10px;
  }
}
