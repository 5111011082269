// Formulários (Login, Cria Conta, Cadastrar Senha, etc)
.form-usuarios {
  // DESKTOP
  @include mq(l) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  // TABLET
  @include mq(m) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

// Link recuperação
.link-rec {
  float: left;
  display: block;
  width: 100%;
  margin-top: -10px;
  font-size: 15px;
}

// Texto opção (Não possui conta? /Já possui conta?)
.texto.opt {
  float: left;
  width: 100%;
  color: $cor_fonte_escura;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

// Espaçamento
.separator {
  float: left;
  width: 100%;
  height: 1px;
  margin: 10px 0;
}

// Título seção
.form-titulo {
  float: left;
  width: 100%;
  font-family: $fonte_geral;
  font-size: 20px;
  font-weight: bold;
  color: $cor_fonte_escura;
  margin-bottom: 40px;
}

// Campo CNPJ
.campo-cnpj {
  display: none;
}

// Texto opção login (Facebook)
.opt-login {
  float: left;
  width: 100%;
  position: relative;
  margin: 20px 0;
  text-align: center;

  span {
    position: relative;
    background: #FFF;
    padding: 0 20px;
    color: $cor_fonte_clara;
    font-size: 16px;
    text-align: center;
  }

  // Linha
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: #DDD;
  }
}
