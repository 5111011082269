// Lateral
.produtos-lateral {
  border-right: 1px solid #DDD;
  padding-right: 20px;

  // MOBILE
  @include mq(s) {
    display: none;
  }
}

// Categorias
.produtos-categorias {
  float: left;
  width: 100%;

  // Item
  li {
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }

  // Link
  a {
    position: relative;
    display: inline-block;
    font-weight: 600;
    color: $cor_fonte_escura;

    &:hover {
      color: $cor_primaria;
    }

    i {
      color: $cor_primaria;
      margin-right: 5px;
    }
  }
}

// Subcategorias
.produtos-categorias li ul {
  margin-top: 10px;

  // Item
  li {
    margin-bottom: 10px;
  }

  // Link
  a {
    font-weight: normal;
  }
}
