// Título
.titulo {
  float: left;
  width: 100%;
  position: relative;
  font-family: $fonte_titulo;
  font-size: 28px;
  line-height: 1.2;
  font-weight: 600;
  color: $cor_fonte_escura;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px;

  // TABLET
  @include mq(m) {
    font-size: 24px;
    margin-bottom: 40px;
  }

  // MOBILE
  @include mq(s) {
    font-size: 20px;
    margin-bottom: 30px;
  }

  // Linha inferior
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    margin: 0 auto;
    width: 100px;
    height: 2px;
    background: $cor_primaria;

    // MOBILE
    @include mq(s) {
      bottom: -10px;
    }
  }
}

// Subtítulo
.subtitulo {
  float: left;
  width: 100%;
  position: relative;
  font-family: $fonte_geral;
  font-size: 20px;
  line-height: 1.2;
  font-weight: bold;
  color: $cor_fonte_escura;
  margin-bottom: 20px;

  &.center {
    text-align: center;
  }
}

// Texto geral
.texto {
  font-family: $fonte_geral;
  font-size: 16px;
  line-height: 1.5;
  color: $cor_fonte_clara;

  &.center {
    text-align: center;
  }

  b,
  strong {
    font-weight: bold;
    color: $cor_fonte_escura;
  }

  // Unresets para CKEDITOR
  em {
    font-style: italic !important;
  }

  ul {
    list-style: disc outside none !important;
    padding-left: 15px;
  }

  ol {
    list-style: decimal outside none !important;
    padding-left: 15px;
  }

  li {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.5;
  }

  span[style] {
    display: block;
    padding: 10px;
  }
}

// Texto (Sem registros)
.empty {
  text-align: center;
  min-height: 100px;

  span {
    font-family: $fonte_geral;
    font-size: 24px;
    color: $cor_fonte_escura;
    margin-bottom: 10px;
  }

  p {
    font-family: $fonte_geral;
    color: $cor_fonte_clara;
  }

  b {
    font-weight: bold;
    color: $cor_fonte_escura;
  }
}

// Links
a,
a:hover,
a:visited,
a:focus,
a:active {
  outline: none;
  text-decoration: none;
}

.link {
  font-family: $fonte_geral;
  transition: 0.2s;
  color: $cor_primaria;

  &:active,
  &:focus,
  &:visited {
    color: $cor_primaria;
  }

  &:hover {
    color: $cor_secundaria !important;
  }
}
