// Conteúdo página
.conta-content {
  padding: 20px;
  border: 1px solid #DDD;

  // MOBILE
  @include mq(s) {
    padding: 10px;
  }
}
