// Botão padrão
.btn {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  background: rgba(#000, .1);
  border: none;
  border-radius: 5px;
  font-family: $fonte_titulo;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  color: $cor_fonte_escura;	
  text-align: center;
  padding: 15px 30px;
  outline: none;
  cursor: pointer;	
  transition: .3s ease-out;
  
  &:hover, &:focus, &:active, &.active {
    background: rgba(#000, .15);
  }
  
  // Inativo
  &[disabled] {
    opacity: .5;
    cursor: default;
  }
  
  i {
    margin-right: 10px;
  }
  
  // Outline
  &.outline {
    background: none;
    border: 1px solid #DDD;
    color: $cor_fonte_clara;
    
    &:hover, &:focus, &:active, &.active {
      background: #DDD;
      color: $cor_fonte_escura;
    }
  }
  
  // Botão primário
  &.btn-primario {
    background: $cor_primaria;
    color: #FFF;
    
    &:hover, &:focus, &:active, &.active {
      background: darken($cor_primaria, 10%);
    }
    
    &.active:hover {
      background: $cor_primaria;
    }
    
    // Outline
    &.outline {
      background: none;
      border: 1px solid $cor_primaria;
      color: $cor_primaria;
      
      &:hover, &:focus, &:active, &.active {
        background: $cor_primaria;
        color: #FFF;
      }
    }
  }
  
  // Botão secundário
  &.btn-secundario {
    background: $cor_secundaria;
    color: #FFF;
    
    &:hover, &:focus, &:active, &.active {
      background: darken($cor_secundaria, 10%);
    }
    
    &.active:hover {
      background: $cor_secundaria;
    }
    
    // Outline
    &.outline {
      background: none;
      border: 1px solid $cor_secundaria;
      color: $cor_secundaria;
      
      &:hover, &:focus, &:active, &.active {
        background: $cor_secundaria;
        color: #FFF;
      }
    }
  }
  
  // Botão pequeno
  &.btn-sm {
    padding: 10px 20px;
    font-size: 14px;
  }
  
  // Botão grande
  &.btn-lg {
    padding: 20px 40px;
    font-size: 20px;
  }
}

// Botão container
.btn-container {
  float: left;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  
  &.left {
    text-align: left;
  }
  
  &.right {
    text-align: right;
  }
}
