// Header principal
.header {
  position: relative;
  z-index: $z_index_header;
  float: left;
  width: 100%;
  background: #fff;
  //padding: 15px 0;
  padding-top: 20px;
  padding-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.26);

  // MOBILE
  @include mq(s) {
    padding-top: 40px;
    padding-bottom: 10px;
    text-align: center;
  }
}

// Conteúdo (Container)
.header .container {
  position: static;
}

.header-content {
  position: relative;
  padding: 0 10px;

  // MOBILE
  @include mq(s) {
    position: static;
  }
}

// Logo
.header-logo {
  display: block;
  float: left;
  max-width: 250px;

  // TABLET
  @include mq(m) {
    padding-top: 11px;
    max-width: 140px;
  }

  // MOBILE
  @include mq(s) {
    float: none;
    display: inline-block;
    vertical-align: top;
    max-width: 150px;
  }
}

// Direita
.header-direita {
  float: right;

  // MOBILE
  @include mq(s) {
    height: 0px;
  }
}

// Botões
.header-botoes {
  float: left;
  text-align: left;
  margin-right: 50px;

  // MOBILE
  @include mq(s) {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    display: none;
  }

  .btn {
    margin-right: 10px;

    // MOBILE
    @include mq(s) {
      font-size: 12px;
      margin: 5px;
      padding: 10px 15px;
    }
  }
}

// Telefones
.header-telefones {
  position: absolute;
  top: -20px;
  right: 10px;
  background-color: $cor_primaria;
  padding: 5px 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  // MOBILE
  @include mq(s) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 3px;
    background: $cor_fundo2;
    border-radius: 0px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
  }

  // Item
  a {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    margin: 0 5px;

    // TABLET
    @include mq(m) {
      font-size: 14px;
    }

    // MOBILE
    @include mq(s) {
      font-size: 14px;
      color: $cor_fonte_escura;
    }

    i {
      margin-right: 3px;

      // MOBILE
      @include mq(s) {
        color: $cor_primaria;
      }
    }
  }
}

.header-email {
  float: right;
  position: absolute;
  top: -14px;
  right: 440px;

  a {
    color: $cor_fonte_escura;
    font-weight: 400;
    font-size: 16px;
    transition: .3s ease-out;

    &:hover {
      color: $cor_primaria;
    }
  }

  i {
    margin-right: 5px;
    color: $cor_primaria;
  }

  // TABLET
  @include mq(m) {
    right: 398px;
  }

  // MOBILE
  @include mq(s) {
    display: none;
  }
}

// Busca
.header-busca {
  position: absolute;
  z-index: 3;
  right: 194px;
  top: 24px;
  width: 100%;
  max-width: 0px;
  opacity: 0;
  overflow: hidden;
  transition: .3s;

  // TABLET
  @include mq(m) {
    top: 22px;
    right: 139px;
  }

  &.active {
    max-width: 400px;
    opacity: 1;

    // TABLET
    @include mq(m) {
      max-width: 580px;
    }
  }

  // Campo
  .campo {
    height: 40px;
    padding-right: 30px;
  }

  // Botão
  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    color: $cor_primaria;
    font-size: 20px;
    cursor: pointer;
    transition: .3s;

    // TABLET
    @include mq(m) {
      font-size: 16px;
    }
  }
}
