.produtos-destaque {
  background: $cor_fundo2;
}

// Bloco produto
.bloco-produto {
  position: relative;
  background: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;

  // Foto
  figure {
    background: #DDD;
    overflow: hidden;

    img {
      transition: .5s ease-out;
    }
  }

  &:hover figure img {
    transform: scale(1.1);
  }

  // Infos
  >div {
    padding: 15px;
    text-align: left;

    // MOBILE
    @include mq(s) {
      padding: 10px;
    }
  }

  // Título
  h2 {
    height: 39px;
    overflow: hidden;
    color: $cor_fonte_escura;
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    transition: .3s ease-out;

    // TABLET
    @include mq(m) {
      height: 60px;
    }

    // MOBILE
    @include mq(s) {
      text-align: center;
      height: 30px;
      font-size: 12px;
    }
  }

  &:hover h2 {
    color: $cor_primaria;
  }

  .segura-info {
    margin-left: 0px;

    h3 {
      font-size: 13px;
      padding-top: 14px;
      color: $cor_primaria;

      // TABLET
      @include mq(m) {
        font-size: 13px
      }
    }

    i {
      margin-right: 5px;
    }

    // MOBILE
    @include mq(s) {
      margin-left: 10px;
      text-align: center;
      padding-bottom: 10px;
    }
  }

  // Botão
  .btn-container {
    float: none;
    margin-top: 10px;

    .btn {

      // MOBILE
      @include mq(s) {
        width: 100%;
        font-size: 12px;
        padding: 10px 5px;
      }
    }

    .btn-sm {
      padding: 5px 12px;
      font-size: 14px;
      text-transform: none;
    }

    // MOBILE
    @include mq(s) {
      display: none;
    }
  }
}

// Bloco serviço
.produtos-home {
  //height: 260px;
  //margin-bottom: 20px;

  // MOBILE
  @include mq(s) {
    margin-bottom: 30px;
    height: auto;
  }

  // TABLET
  @include mq(m) {
    //height: 290px;
  }

  .segura-icone {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;

  }

  .img-icone {
    display: inline-block;
    width: 100px;

    // Imagem
    img {
      transition: .3s ease-out;

      // MOBILE
      @include mq(s) {
        width: 80px;
        display: inline-block;
      }
    }
  }

  &:hover img {
    transform: scale(1.1);
  }

  // Titulo Serviço
  .produto-home-titulo {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: $cor_fonte_escura;

    // MOBILE
    @include mq(s) {
      font-size: 15px;
    }
  }

}
