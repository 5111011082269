// Barra principal
.filtros-carrinho {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;

  li {
    display: block;
    float: left;
    position: relative;
  }

  // Links geral
  a {
    display: inline-block;
    width: 100%;
    font-family: $fonte_geral;
    font-size: 15px;
    transition: .3s ease-out;

    // TABLET
    @include mq(m) {
      font-size: 13px;
    }

  }

  // MOBILE
  @include mq(s) {
    display: none;
  }
}

/// 1 Level
.filtros-carrinho .filtro-menu-1-level {
  display: inline-block;
  float: none;
  width: auto;
  text-align: left;

  // Item
  >li {
    margin: 0 10px;

    // TABLET
    @include mq(m) {
      padding: 0 5px;
    }

    // Remove o padding do primeiro link
    &:first-child {
      padding-left: 0px;
    }

    // Remove o padding do último link
    &:last-child {
      padding-right: 0px;
    }

    &.active>a {
      color: $cor_primaria;
    }

    // Link
    >a {
      z-index: 2;
      color: $cor_fonte_escura;
      padding: 5px 15px;
      padding-top: 8px;
      border: 2px solid rgba(179, 179, 179, 0.3);
      background: #FFF;
      text-transform: uppercase;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      position: relative;

      // TABLET
      @include mq(m) {
        padding: 5px 10px;
      }

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 51%;
        right: 51%;
        bottom: -5px;
        background: $cor_primaria;
        height: 2px;
        -webkit-transition-property: left, right;
        transition-property: left, right;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }

      /*&:hover:before, &:focus:before, &:active:before {
        left: 0;
        right: 0;
      }*/
      &:after {
        background: $cor_primaria;
        position: absolute;
        content: '';
        height: 2px;
        right: 0;
        left: 0;
        top: 0;
      }

      // Busca
      &.open-busca {
        color: $cor_primaria;
        font-size: 18px;

        &:before {
          display: none;
        }
      }
    }
  }
}

// 2 Level
.filtros-carrinho .filtro-menu-2-level {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 4;
  width: 225px;
  padding: 10px 0;
  background: $cor_primaria;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0px;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, -20px);
  transition: .3s ease;
  border-top: none;
  opacity: 0;

  // TABLET
  @include mq(m) {
    width: 200px;
    right: -215px;
    top: 45px;
    left: 0px;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }

  .filtro-menu-2-level-fechar {
    position: absolute;
    top: 14px;
    left: -25px;
    color: $cor_fonte_escura;
    font-weight: bold;
  }

  // Ponta
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -15px;
    left: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 15px 20px;
    border-color: transparent transparent $cor_primaria transparent;

    // TABLET
    @include mq(m) {
      top: -9px;
      border-width: 0 15px 10px 15px;
    }
  }

  &:after {
    content: '';
    display: none;
    position: absolute;
    top: -22px;
    left: 0px;
    width: 100%;
    height: 24px;
  }

  // Item
  li {
    float: left;
    width: 100%;
    transition: .3s ease;

    // Link
    a {
      padding: 5px 20px;
      color: #FFF;
      text-transform: uppercase;

      // TABLET
      @include mq(m) {
        padding: 0px 10px;
      }

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      background-color: darken($cor_primaria, 5);
    }
  }
}

.filtro-menu-1-level>li:hover>.filtro-menu-2-level {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);

  &:after {
    display: block;
  }
}

// 3 Level
.filtros-carrinho .filtro-menu-3-level {
  position: absolute;
  top: -10px;
  right: -225px;
  width: 225px;
  padding: 10px 0;
  background: $cor_primaria;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  opacity: 0;
  visibility: hidden;
  transform: translate(-20px, 0);
  transition: .3s ease;

  &.left {
    right: inherit;
    left: -250px;
    transform: translate(20px, 0);
  }

  // TABLET
  @include mq(m) {
    right: -200px;
    width: 200px;
    top: -5px;
    padding: 5px 0;
  }

  // Item
  li {
    float: left;
    width: 100%;
    transition: .3s ease;

    // Link
    a {
      padding: 5px 20px;
      color: #FFF;
      font-size: 13px;
      text-transform: uppercase;

      // TABLET
      @include mq(m) {
        padding: 0px 10px;
      }

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      background-color: darken($cor_primaria, 5);
    }
  }
}

.filtros-carrinho .filtro-menu-2-level>li:hover>.filtro-menu-3-level {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

// 4 Level
.filtros-carrinho .filtro-menu-4-level {
  position: absolute;
  top: -10px;
  right: -225px;
  width: 225px;
  padding: 10px 0;
  background: $cor_primaria;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  opacity: 0;
  visibility: hidden;
  transform: translate(-20px, 0);
  transition: .3s ease;

  &.left {
    right: inherit;
    left: -250px;
    transform: translate(20px, 0);
  }

  // TABLET
  @include mq(m) {
    right: -200px;
    width: 200px;
    top: -5px;
    padding: 5px 0;
  }

  // Item
  li {
    float: left;
    width: 100%;
    transition: .3s ease;

    // Link
    a {
      padding: 5px 20px;
      color: #FFF;
      font-size: 13px;
      text-transform: uppercase;

      // TABLET
      @include mq(m) {
        padding: 0px 10px;
      }

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      background-color: darken($cor_primaria, 5);
    }
  }
}

.filtros-carrinho .filtro-menu-3-level>li:hover>.filtro-menu-4-level {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

// Filtro Mobile
.filtros-carrinho-mobile {
  position: relative;
  margin-bottom: 35px;
  text-align: center;

  // DESKTOP
  @include mq(l) {
    display: none;
  }

  // TABLET
  @include mq(m) {
    display: none;
  }

  // MOBILE
  @include mq(s) {
    display: block;
  }
}

@charset "UTF-8";

// Botão abrir / fechar menu mobile FILTRO
.menu-btn-mobile-filtros {
  position: absolute;
  top: 12px;
  left: 16px;
  z-index: 5;
  width: 30px;
  height: 30px;
  border-top: 2px solid $cor_fonte_escura;
  cursor: pointer;
  -webkit-backface-visibility: hidden;

  // DESKTOP
  @include mq(l) {
    display: none;
  }

  // TABLET
  @include mq(m) {
    display: none;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    height: 2px;
    margin-top: 5px;
    background: $cor_fonte_escura;
    position: relative;
    transition: transform .2s ease;
  }

  &.active {
    border-top-color: transparent;

    &::before {
      margin-top: 0;
      top: 6px;
      transform: rotate(135deg);
    }

    &::after {
      margin-top: 0;
      top: 3px;
      transform: rotate(45deg);
    }
  }
}

/* -------------------------------- 

Main Components 

-------------------------------- */

// Fundo (Preto transparente)
.cd-dropdown-wrapper-filtros {
  position: fixed;
  z-index: 7;

  // DESKTOP
  @include mq(l) {
    display: none;
  }

  &.active {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }
}

.cd-dropdown-filtros h2,
.cd-dropdown-content-filtros a,
.cd-dropdown-content-filtros ul a {
  font-size: 18px;
  height: 50px;
  line-height: 50px;
}

.cd-dropdown-filtros h2,
.cd-dropdown-content-filtros a,
.cd-dropdown-content-filtros ul a,
.cd-dropdown-content-filtros .cd-divider {
  padding: 0 20px;
}

// Barra lateral
.cd-dropdown-filtros {
  position: fixed;
  z-index: $z_index_menu_mobile;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #FFF;
  visibility: hidden;
  box-shadow: 2px 0 5px rgba(0, 0, 0, .26);
  /* Force Hardware Acceleration */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: translateX(-300px);
  -moz-transform: translateX(-300px);
  -ms-transform: translateX(-300px);
  -o-transform: translateX(-300px);
  transform: translateX(-300px);
  -webkit-transition: -webkit-transform 0.2s 0s, visibility 0s 0.5s;
  -moz-transition: -moz-transform 0.2s 0s, visibility 0s 0.5s;
  transition: transform 0.2s 0s, visibility 0s 0.5s;
}

.cd-dropdown-filtros h2 {
  /* dropdown title - mobile version only */
  position: relative;
  z-index: 1;
  color: #FFF;
  background-color: $cor_primaria;
  text-transform: uppercase;
}

.cd-dropdown-filtros.dropdown-is-active {
  visibility: visible;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.2s 0s, visibility 0s 0s;
  -moz-transition: -moz-transform 0.2s 0s, visibility 0s 0s;
  transition: transform 0.2s 0s, visibility 0s 0s;
}

.cd-dropdown-content-filtros,
.cd-dropdown-content-filtros ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  background-color: #FFF;
  text-align: left;
}

// Links
.cd-dropdown-content-filtros a,
.cd-dropdown-content-filtros ul a {
  display: block;
  font-family: $fonte_geral;
  color: $cor_fonte_escura;
  font-weight: 300;
  font-size: 18px;
  /* truncate text with ellipsis if too long */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
}

.cd-dropdown-content-filtros li:first-of-type>a,
.cd-dropdown-content-filtros ul li:first-of-type>a {
  border-top-width: 0;
}

.cd-dropdown-content-filtros li:last-of-type>a,
.cd-dropdown-content-filtros ul li:last-of-type>a {
  border-bottom-width: 1px;
}

.cd-dropdown-content-filtros .cd-divider,
.cd-dropdown-content-filtros ul .cd-divider {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $cor_secundaria;
  font-weight: bold;
  letter-spacing: 1px;
  color: $cor_fonte_clara;
}

.cd-dropdown-content-filtros .cd-divider+li>a,
.cd-dropdown-content-filtros ul .cd-divider+li>a {
  border-top-width: 0;
}

.cd-dropdown-content-filtros a,
.cd-dropdown-content-filtros .cd-divider,
.cd-dropdown-content-filtros ul a,
.cd-dropdown-content ul .cd-divider {
  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}

.cd-dropdown-content-filtros.is-hidden,
.cd-dropdown-content-filtros ul.is-hidden {
  /* push the secondary dropdown items to the right */
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.cd-dropdown-content-filtros.is-hidden>li>a,
.cd-dropdown-content-filtros.is-hidden>.cd-divider,
.cd-dropdown-content-filtros.move-out>li>a,
.cd-dropdown-content-filtros.move-out>.cd-divider,
.cd-dropdown-content-filtros ul.is-hidden>li>a,
.cd-dropdown-content-filtros ul.is-hidden>.cd-divider,
.cd-dropdown-content-filtros ul.move-out>li>a,
.cd-dropdown-content-filtros ul.move-out>.cd-divider {
  /* lower down the dropdown items opacity - when secondary dropdown slides in or for dropdown items hidden on the right */
  opacity: 0;
}

.cd-dropdown-content-filtros.move-out>li>a,
.cd-dropdown-content-filtros.move-out>.cd-divider,
.cd-dropdown-content-filtros ul.move-out>li>a,
.cd-dropdown-content-filtros ul.move-out>.cd-divider {
  /* push the dropdown items to the left when secondary dropdown slides in */
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.dropdown-is-active .cd-dropdown-content-filtros,
.dropdown-is-active .cd-dropdown-content-filtros ul {
  -webkit-overflow-scrolling: touch;
}

// Título subitems
.cd-dropdown-content-filtros .see-all a {
  /* different style for the See all links */
  background: none;
  text-transform: none;
  text-align: left;
  color: $cor_fonte_escura;
  font-size: 20px !important;
  border-bottom: 1px solid rgba(#000, .1);
  padding: 5px 20px;
  height: auto;
}

@-webkit-keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-moz-keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: visible;
  }
}

@-moz-keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: visible;
  }
}

@keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: visible;
  }
}

.has-children>a,
.go-back a {
  position: relative;
}

// Setas
.has-children>a::before,
.has-children>a::after,
.go-back a::before,
.go-back a::after {
  /* arrow icon in CSS - for element with nested unordered lists */
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1px;
  display: inline-block;
  height: 2px;
  width: 10px;
  background: $cor_primaria;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.go-back a::before,
.go-back a::after {
  background: #FFF;
}

.has-children>a::before,
.go-back a::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.has-children>a::after,
.go-back a::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.has-children>a {
  padding-right: 40px;
}

.has-children>a::before,
.has-children>a::after {
  /* arrow goes on the right side - children navigation */
  right: 20px;
  -webkit-transform-origin: 9px 50%;
  -moz-transform-origin: 9px 50%;
  -ms-transform-origin: 9px 50%;
  -o-transform-origin: 9px 50%;
  transform-origin: 9px 50%;
}

// Link (Voltar)
.cd-dropdown-content-filtros .go-back a {
  padding-left: 40px;
  background: $cor_primaria;
  color: #FFF;
}

.cd-dropdown-content-filtros .go-back a::before,
.cd-dropdown-content-filtros .go-back a::after {
  /* arrow goes on the left side - go back button */
  left: 20px;
  -webkit-transform-origin: 1px 50%;
  -moz-transform-origin: 1px 50%;
  -ms-transform-origin: 1px 50%;
  -o-transform-origin: 1px 50%;
  transform-origin: 1px 50%;
}

.no-js .cd-dropdown-wrapper-filtros:hover .cd-dropdown-filtros {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.cd-dropdown-header-filtros {
  background: $cor_primaria;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;

  .cd-dropdown-logo-filtros {
    display: inline-block;
    margin: 0;
    width: 250px;
    height: auto;
    line-height: 100%;
  }
}

.titulo-filtros-mobile {
  color: #FFF !important;
  font-weight: bold;
  font-size: 20px;
  height: 22px;
}

.menu-btn-titulo {
  position: absolute;
  left: 40px;
  top: -5px;
  width: 200px;
  color: $cor_fonte_escura;
  font-size: 14px;
  text-transform: uppercase;
}

.menu-btn-mobile-filtros-segura {
  position: relative;
  background: #FFF;
  display: inline-block;
  width: 270px;
  height: 40px;
  text-align: center;
  border: 2px solid rgba(179, 179, 179, 0.3);

  &:after {
    background-image: linear-gradient(to right, #83c7ee, #6bb2d9, #529ec5, #368ab1, #0e769d);
    position: absolute;
    content: '';
    height: 2px;
    right: 0;
    left: 0;
    top: 0;
  }
}

/*# sourceMappingURL=style.css.map */


.btn-container-filtros {

  // TABLET
  @include mq(m) {
    display: none;
  }
}
