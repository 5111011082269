// Lateral
.conta-lateral {
  border: 1px solid #DDD;

  // MOBILE
  @include mq(s) {
    margin-bottom: 20px;
  }
}

// Header lateral
.conta-lateral-header {
  float: left;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #DDD;
  border-left: 3px solid #DDD;

  // Foto
  figure {
    float: left;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #DDD;
    overflow: hidden;
  }

  // Direita
  > div {
    float: left;
    width: calc(100% - 80px);
    padding-left: 10px;
    padding-top: 10px;

    // TABLET
    @include mq(m) {
      padding-top: 0px;
    }

    // Nome
    span {
      display: block;
      color: $cor_fonte_escura;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    // E-mail
    small {
      display: block;
      color: $cor_fonte_clara;
      font-size: 14px;
      font-weight: normal;
      word-wrap: break-word;
    }
  }
}

// Menu
.conta-lateral-menu {
  float: left;
  width: 100%;

  // MOBILE
  @include mq(s) {
    display: none;
  }

  // Item
  li {
    float: left;
    width: 100%; 

    // Link
    a {
      display: inline-block;
      width: 100%;
      padding: 12px 20px;            
      font-size: 16px;
      font-weight: normal;
      color: $cor_fonte_escura;
      border-left: 3px solid #DDD;
      transition: .3s ease-out    ;

      &:hover {
        background: $cor_primaria;
        border-color: $cor_secundaria;
        color: #FFF;
      }

      i {
        color: $cor_primaria;
        margin-right: 10px;
        transition: .3s ease-out;
      }
      &:hover i {
        color: $cor_secundaria;
      }
    }
  }
}

// Botão abrir / fechar menu mobile
.conta-lateral-menu-btn { 
  float: left;
  width: 100%;
  padding: 10px;
  padding-bottom: 0px;
  background: $cor_primaria;
  border-left: 3px solid #DDD;

  // DESKTOP
  @include mq(l) {			
    display: none;
  }

  // TABLET
  @include mq(m) {			
    display: none;
  }

  // Texto
  span {
    display: inline-block;
    vertical-align: top;
    margin-top: 0px;
    color: #FFF;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;  
  }

  // Botão
  i {
    float: left; 
    margin-right: 10px;      
    margin-top: 3px;      
    width: 30px;
    height: 30px;
    border-top: 2px solid #FFF;
    cursor: pointer;
    -webkit-backface-visibility: hidden;

    &::before, &::after {
      content: '';
      display: block;
      height: 2px;
      margin-top: 5px;
      background: #FFF;
      position: relative;
      transition: transform .2s ease;
    }
  }    
}
