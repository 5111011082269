// Footer geral
.footer {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 0px;
  //background: $cor_fundo2;
  overflow: hidden;

  // MOBILE
  @include mq(s) {
    padding-bottom: 0px;
  }
}

// Conteúdo container
.footer-content {
  margin-bottom: 40px;
}

// Coluna
.footer-bloco {

  &:not(:last-child) {

    // MOBILE
    @include mq(s) {
      margin-bottom: 40px;
    }
  }

  // Título
  h2 {
    font-family: $fonte_titulo;
    color: $cor_primaria;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 20px;

    // TABLET
    @include mq(m) {
      font-size: 18px;
    }

    // MOBILE
    @include mq(s) {
      font-size: 20px;
    }
  }

  // Lista links
  .footer-lista {

    // Item
    li {
      float: left;
      width: 100%;
      margin-bottom: 15px;
      font-family: $fonte_titulo;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.2;
      color: $cor_fonte_clara;

      // TABLET
      @include mq(m) {
        font-size: 10px
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    // Link
    a {
      color: $cor_fonte_clara;
      transition: .2s ease-out;

      &:hover {
        color: $cor_primaria;
      }
    }

    // Ícone
    i {
      margin-right: 10px;
    }
  }
}

// Mapa do site
.footer-mapa {}

// Aplicativo
.footer-aplicativo {

  // Texto
  h3 {
    font-family: $fonte_titulo;
    margin-bottom: 20px;
  }

  // Botões
  .btn {
    width: 200px;
    margin: 5px 0;
  }
}

// Newsletter
.footer-newsletter {
  background: $cor_primaria;
  padding: 30px 0px;
  margin-bottom: 40px;
  border-radius: 10px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  //background-attachment: fixed;

  // MOBILE
  @include mq(s) {
    padding: 30px 40px;
  }

  // Máscara
  .mascara {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, .5);
    border-radius: 10px;
  }

  h2 {
    color: #FFF;
    font-size: 30px;
    margin-bottom: 0px;
    width: auto;
    //float: left;
    text-align: center;

  }

  // Texto
  .texto {
    font-family: $fonte_titulo;
    font-size: 15px;
    font-weight: normal;
    color: #FFF;
    width: auto;
    //float: left;
    text-align: center;
    //margin-top: 11px;
    margin-left: 15px;
    margin-bottom: 10px;

    // TABLET
    @include mq(m) {
      margin-left: 0px;
    }

    // MOBILE
    @include mq(s) {
      margin-left: 0px;
      margin-bottom: 20px;
    }
  }

  // Campo
  .campo-container {
    float: left;
    width: calc(100% - 120px);
    margin: 0px;
    margin-bottom: 15px;

    // TABLET
    @include mq(m) {
      width: 100%;
      margin-bottom: 10px;
    }

    // MOBILE
    @include mq(s) {
      width: 100%;
      margin-bottom: 10px;
    }

    // Campo
    .campo {
      height: 40px;
      border: none;

      // DESKTOP
      @include mq(l) {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  // Botão
  .btn {
    float: left;
    width: 120px;
    height: 40px;
    padding: 0px;
    font-size: 14px;

    // DESKTOP
    @include mq(l) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    // TABLET
    @include mq(m) {
      width: 100%;
    }

    // MOBILE
    @include mq(s) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

// Atendimento
.footer-atendimento {
  padding-left: 40px;

  // TABLET
  @include mq(m) {
    padding-left: 20px;
  }

  // MOBILE
  @include mq(s) {
    padding-left: 0px;
  }

  .footer-lista li {
    width: 100%;
  }
}

// Barra footer
.barra-footer {
  position: relative;
  padding-bottom: 10px;
  font-family: $fonte_titulo;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.2;

  // Linha
  .line {
    float: left;
    width: 100%;
    height: 1px;
    margin-bottom: 10px;
    background: rgba(#000, .1);
  }

  [class^="grid-"]:not(.redes-sociais) {
    padding-top: 5px;
    padding-bottom: 10px;
  }

  // Copyright
  .copyright {
    text-align: center;
  }

  // Desenvolvimento
  .development {
    text-align: right;

    // MOBILE
    @include mq(s) {
      text-align: center;
    }

    a {
      color: $cor_fonte_clara !important;
    }
  }
}

// Redes sociais
.redes-sociais {
  text-align: left;

  // MOBILE
  @include mq(s) {
    text-align: center;
  }

  // Item
  a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 43px;
    border-radius: 50%;
    text-align: center;
    margin: 0 5px;
    background: $cor_primaria;
    transition: .3s ease-out;

    &:hover {
      background: $cor_secundaria;
    }

    i {
      color: #FFF;
      font-size: 20px;
      transition: .3s ease-out;
    }

    /* &:hover i {
      color: $cor_primaria;
    } */
  }
}

.footer-seguro {
  padding: 15px;

  // MOBILE
  @include mq(s) {
    padding: 0px;
    padding-left: 15px;
  }
}
